import React from 'react';
import { Button } from '@axmit/clp-library';
import Icon from 'antd/es/icon';
import { StickyBtnBlockHooks } from 'hooks/StickyBtnBlock.hooks';
import { graphql, useStaticQuery } from 'gatsby';
import { getTranslates } from 'helpers/translate.helper';
import { useTranslation } from 'react-i18next';

interface IComponentProps {
  onClick: () => void;
}

const StickyBtnBlock = ({ onClick }: IComponentProps) => {
  const data = useStaticQuery(query);
  const {
    i18n: { language }
  } = useTranslation();
  const { isStuck } = StickyBtnBlockHooks.useSticky();

  const classes = `sticky-btn-block ${isStuck ? 'sticky-btn-block_stuck' : ''}`;

  return (
    <div className={classes}>
      <Button type="primary" size="small" onClick={onClick}>
        <span>
          {getTranslates(data.allStrapiClpLandCommon, 'sticky__btn', language)}
          <Icon type="right" className="ml-20" />
        </span>
      </Button>
    </div>
  );
};

export default StickyBtnBlock;

export const query = graphql`
  query {
    allStrapiClpLandCommon {
      edges {
        node {
          sticky__btn__en
          sticky__btn__fr
        }
      }
    }
  }
`;

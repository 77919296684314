import { AppLandingHelper } from '@axmit/router-app-landing';
import { EClientRoutes } from 'const';

const redirectToNewGift = () => {
  AppLandingHelper.toggleRoute(EClientRoutes.NewGift);
};

export const redirectHelper = {
  redirectToNewGift
};

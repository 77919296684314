import React, { PropsWithChildren } from 'react';
import { Typography } from 'antd';
import { CardStepByStep } from '@axmit/clp-library';

interface IComponentProps {
  number?: number;
  label: string;
  subLabel: string;
}

type AllProps = PropsWithChildren<IComponentProps>;

export const LandingContentCard = ({ number, label, subLabel, children }: AllProps) => {
  return (
    <>
      <CardStepByStep className="mt-50 mb-25" number={number} label={label} subLabel={subLabel} />

      <Typography.Text className="clp-typography-text clp-typography-text_size_md">{children}</Typography.Text>
    </>
  );
};

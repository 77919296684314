import React from 'react';

const useSticky = () => {
  const [isStuck, setIsStuck] = React.useState(false);

  if (typeof document === 'undefined') {
    return { isStuck };
  }

  const body = document.getElementsByTagName('body')[0];

  const handleWindowScroll = () => {
    const { clientHeight } = body;
    const footer = document.getElementsByClassName('clp-footer')[0];
    const footerHeight = footer?.clientHeight || 0;
    const distanceToPageBottom = body.scrollHeight - window.scrollY;

    const isBlockStuck = distanceToPageBottom <= clientHeight + footerHeight;
    setIsStuck(isBlockStuck);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);
    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, []);

  return { isStuck };
};

export const StickyBtnBlockHooks = {
  useSticky
};

import React from 'react';
import { Container, LandingRow } from '@axmit/clp-library';
import { Col, Row } from 'antd/es/grid';
import Typography from 'antd/es/typography';
import { LandingContentCard } from 'components/LandingContentCard';
import SEO from 'components/Seo';
import { ELandingRoutes } from 'const';
import { graphql, Link } from 'gatsby';
import StickyBtnBlock from 'components/StickyBtnBlock';
import Img from 'gatsby-image';
import { redirectHelper } from 'helpers/redirect.helper';
import { getTranslates } from 'helpers/translate.helper';
import { useTranslation } from 'react-i18next';

export default ({ data }) => {
  const {
    i18n: { language }
  } = useTranslation();

  return (
    <div className="landing-wrapper how-landing-wrapper">
      <SEO title={getTranslates(data.allStrapiClpLandCommon, 'header__how', language)} />

      <Container>
        <LandingRow
          className="step-container-1"
          leftSlot={
            <>
              <Typography.Title className="clp-typography-title clp-typography-title_size_md mb-25">
                {getTranslates(data.allStrapiClpHowPage, 'header', language)}
              </Typography.Title>

              <div className="land-img-mob land-img-mob_full">
                <Img
                  fixed={data.MapMobile.childImageSharp.fixed}
                  className="land-img-mob"
                  alt="map small"
                  imgStyle={{ objectFit: 'contain' }}
                />
              </div>

              <LandingContentCard
                number={1}
                label={getTranslates(data.allStrapiClpHowPage, 'step1__header', language)}
                subLabel={getTranslates(data.allStrapiClpHowPage, 'step1__label', language)}
              >
                <span className="content-card">{getTranslates(data.allStrapiClpHowPage, 'step1__content', language)}</span>
              </LandingContentCard>
            </>
          }
          rightSlot={
            <div className="land-img-desk">
              <Img
                className="land-img-desk"
                fixed={data.MapDesktop.childImageSharp.fixed}
                alt="map"
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
          }
        />

        <LandingRow
          className="step-container-2"
          revertOrder
          leftSlot={
            <div className="land-img-desk">
              <Img fixed={data.PenDesktop.childImageSharp.fixed} alt="pen" imgStyle={{ objectFit: 'contain' }} />
            </div>
          }
          rightSlot={
            <>
              <div className="land-img-mob land-img-mob_full">
                <Img fixed={data.PenMobile.childImageSharp.fixed} alt="pen small" imgStyle={{ objectFit: 'contain' }} />
              </div>

              <LandingContentCard
                number={2}
                label={getTranslates(data.allStrapiClpHowPage, 'step2__header', language)}
                subLabel={getTranslates(data.allStrapiClpHowPage, 'step2__label', language)}
              >
                <p className="content-card mb-25">{getTranslates(data.allStrapiClpHowPage, 'step2__content', language)}</p>
                <span className="content-card">
                  {getTranslates(data.allStrapiClpHowPage, 'step2__before_link', language)}
                  <Link to={`/${ELandingRoutes.FAQ}`} className="faq-link">
                    {getTranslates(data.allStrapiClpHowPage, 'step2__link', language)}
                  </Link>
                  {getTranslates(data.allStrapiClpHowPage, 'step2__after_link', language)}
                </span>
              </LandingContentCard>
            </>
          }
        />

        <Row type="flex" align="middle" className="step-container-3">
          <Col xs={24} md={12}>
            <div className="land-img-mob land-img-mob_full">
              <Img fixed={data.BookMobile.childImageSharp.fixed} alt="book small" imgStyle={{ objectFit: 'contain' }} />
            </div>

            <LandingContentCard
              number={3}
              label={getTranslates(data.allStrapiClpHowPage, 'step3__header', language)}
              subLabel={getTranslates(data.allStrapiClpHowPage, 'step3__label', language)}
            >
              <span className="content-card">{getTranslates(data.allStrapiClpHowPage, 'step3__content', language)}</span>
            </LandingContentCard>
          </Col>

          <Col xs={0} md={12} className="below-content">
            <div className="land-img-desk img-reflection-x">
              <Img fixed={data.BookDesktop.childImageSharp.fixed} alt="book" imgStyle={{ objectFit: 'contain' }} />
            </div>
          </Col>
        </Row>

        <LandingRow
          className="step-container-4"
          revertOrder
          leftSlot={
            <div className="land-img-desk specific-img-luggage">
              <Img fixed={data.LuggageDesktop.childImageSharp.fixed} alt="luggage" imgStyle={{ objectFit: 'contain' }} />
            </div>
          }
          rightSlot={
            <>
              <div className="land-img-mob land-img-mob_full mt-50">
                <Img fixed={data.LuggageMobile.childImageSharp.fixed} alt="luggage small" imgStyle={{ objectFit: 'contain' }} />
              </div>

              <LandingContentCard
                number={4}
                label={getTranslates(data.allStrapiClpHowPage, 'step4__header', language)}
                subLabel={getTranslates(data.allStrapiClpHowPage, 'step4__label', language)}
              >
                <span className="content-card">{getTranslates(data.allStrapiClpHowPage, 'step4__content', language)}</span>
              </LandingContentCard>
            </>
          }
        />

        <LandingRow
          leftSlot={
            <>
              <div className="land-img-mob land-img-mob_full">
                <Img fixed={data.TickersMobile.childImageSharp.fixed} alt="tickers small" imgStyle={{ objectFit: 'contain' }} />
              </div>

              <LandingContentCard
                number={5}
                label={getTranslates(data.allStrapiClpHowPage, 'step5__header', language)}
                subLabel={getTranslates(data.allStrapiClpHowPage, 'step5__label', language)}
              >
                <p className="content-card mb-25">{getTranslates(data.allStrapiClpHowPage, 'step5__content1', language)}</p>
                <p className="content-card">{getTranslates(data.allStrapiClpHowPage, 'step5__content2', language)}</p>
              </LandingContentCard>
            </>
          }
          rightSlot={
            <div className="land-img-desk specific-img-tickers">
              <Img fixed={data.TickersDesktop.childImageSharp.fixed} alt="tickers" imgStyle={{ objectFit: 'contain' }} />
            </div>
          }
        />
        <StickyBtnBlock onClick={redirectHelper.redirectToNewGift} />
      </Container>
    </div>
  );
};

export const query = graphql`
  query {
    MapDesktop: file(relativePath: { eq: "images/julesTravel.png" }) {
      childImageSharp {
        fixed(height: 500, quality: 100, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    MapMobile: file(relativePath: { eq: "images/julesMapMobile.png" }) {
      childImageSharp {
        fixed(height: 213, quality: 100, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    PenDesktop: file(relativePath: { eq: "images/julesPen.png" }) {
      childImageSharp {
        fixed(height: 500, quality: 100, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    PenMobile: file(relativePath: { eq: "images/julesPen.png" }) {
      childImageSharp {
        fixed(height: 400, quality: 100, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    BookDesktop: file(relativePath: { eq: "images/julesBook.png" }) {
      childImageSharp {
        fixed(height: 633, quality: 100, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    BookMobile: file(relativePath: { eq: "images/julesBookMobile.png" }) {
      childImageSharp {
        fixed(height: 176, quality: 100, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    LuggageDesktop: file(relativePath: { eq: "images/julesLuggage.png" }) {
      childImageSharp {
        fixed(height: 633, quality: 100, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    LuggageMobile: file(relativePath: { eq: "images/julesLuggageMob.png" }) {
      childImageSharp {
        fixed(height: 256, quality: 100, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    TickersDesktop: file(relativePath: { eq: "images/julesTickers.png" }) {
      childImageSharp {
        fixed(height: 496, quality: 100, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    TickersMobile: file(relativePath: { eq: "images/julesTickers.png" }) {
      childImageSharp {
        fixed(height: 428, quality: 100, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    allStrapiClpLandCommon {
      edges {
        node {
          header__how__en
          header__how__fr
        }
      }
    }
    allStrapiClpHowPage {
      edges {
        node {
          header__en
          header__fr
          step1__label__en
          step1__label__fr
          step1__header__en
          step1__header__fr
          step1__content__en
          step1__content__fr
          step2__label__en
          step2__label__fr
          step2__header__en
          step2__header__fr
          step2__content__en
          step2__content__fr
          step2__before_link__en
          step2__before_link__fr
          step2__link__en
          step2__link__fr
          step2__after_link__en
          step2__after_link__fr
          step3__label__en
          step3__label__fr
          step3__header__en
          step3__header__fr
          step3__content__en
          step3__content__fr
          step4__label__en
          step4__label__fr
          step4__header__en
          step4__header__fr
          step4__content__en
          step4__content__fr
          step5__label__en
          step5__label__fr
          step5__header__en
          step5__header__fr
          step5__content1__en
          step5__content1__fr
          step5__content2__en
          step5__content2__fr
        }
      }
    }
  }
`;
